$(document).ready(function() {
	'use strict';
	var lowerCaseName = "username".toLowerCase();
	$(".stp2").hide();
	//recover password
	$('#password-recover-form').validate({
		rules : {
			recoveremail : 'required',
			recoverpassword : {
        		required: true,
        		minlength: 8
      		},
			recoverpassword2: {
        		equalTo: '#recoverpassword'
      		}
		},
		submitHandler : function(form, event) {
			event.preventDefault();
			var email = $(form).find('#recoveremail').val();
			var password = $(form).find('#recoverpassword').val();

			$.ajax({
				type : $(form).attr('method'),
				url : $(form).attr('action'),
				data : {
					email : email,
					password : password
				},
				dataType : 'json',
				success : function(xdata) {
					if (xdata.success) {
						showMessageBox('success', msg['MSG_RECOVEREMAILSENT']);
						$(".step-login-text.second-step").removeClass("off-state");
						$(".stp1").hide();
						$(".stp2").show();
					} else {
						showMessageBox('danger', msg['MSG_RECOVEREMAILSENT_ERROR']);
					}
				}
			});
		}
	});

	$('#login-form').validate({
		rules : {
			username : 'required',
			password : 'required'
		}
	});

	/*$("#username").on('change keyup paste',function(){
	 $(this).val($(this).val().toLowerCase());
 })*/

  $('.login-toggle-mode').on('click', function(event) {
    event.preventDefault();
    var $this = $(this);
		if($(".eye:visible").length)
        $(".eye").hide();
    else
        $(".eye").show();
    $($this.attr('data-show')).slideDown();
    $($this.attr('data-hide')).slideUp();
  });
});
