'use strict';

var dropCookie = true;

function createCookie(name, value, days) {
    var expires;
    if (days) {
        var date = new Date();
        date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
        expires = '; expires=' + date.toGMTString();
    } else {
        expires = '';
    }
    if (dropCookie) {
        document.cookie = name + '=' + value + expires + '; path=/';
    }
}

function checkCookie(name) {
    var nameEQ = name + "=";
    var ca = document.cookie.split(';');
    for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == ' ') c = c.substring(1, c.length);
        if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
}

function eraseCookie(name) {
    createCookie(name, '', -1);
}

function validateTags(id) {
    const tag = $(id);
    const checkboxes = tag.find('input[type=checkbox]').toArray();
    var valid = checkboxes.some(input => $(input).is(':checked'));
    var oneIsChecked = !tag.hasClass('tags-single') || checkboxes.filter(input => $(input).is(':checked')).length == 1;

    tag.removeClass('error');
    tag.siblings('label.error').remove();

    if (!(valid && oneIsChecked)) {
        const classes = tag.siblings('label').attr('class');
        tag.addClass('error');
        $(`<label class="${classes} error">${messages_requiredfield}</label>`).insertAfter(tag);
    }
    
    return valid && oneIsChecked;
}

function validateSelect(id) {
    var value = document.getElementById(id).value;
    if (!value || value == '') {
        $(id).addClass('error');
        return false;
    }
    else {
        $(id).removeClass('error');
        $(".inline-checkbox").removeAttr("checked");
        var sel = document.getElementById(value);
        if (sel) {
            $(sel).attr("checked", "checked");
            return true;
        }
        return false;
    }
}

function showMessageBox(type, message, extra, callback=()=>{}) {
    var boxType = 'alert-' + type;
    var content = '';
    if (extra) {
        content = '<div>' + extra.content + '</div>';
    }
    var messageBox = '<div class="alert ' + boxType + ' fade in">' + message + content + '</div>';
    $('div.alert-box').html(messageBox);
    setTimeout(function () {
        $('div.alert-box').html('');
        callback();
    }, 10000);
}

// COLLAPSE PUBLIC CLASS DEFINITION
// ================================

var Collapse = function (element, options) {
    this.$element = $(element);
    this.options = $.extend({}, Collapse.DEFAULTS, options);
    this.$trigger = $('[data-toggle="collapse"][href="#' + element.id + '"],' +
        '[data-toggle="collapse"][data-target="#' + element.id + '"]');

    if (this.options.parent) {
        this.$parent = this.getParent();
    } else {
        this.addAriaAndCollapsedClass(this.$element, this.$trigger);
    }

    this.toggle();
};

Collapse.DEFAULTS = {
    toggle: true
};

Collapse.prototype.show = function () {
    if (this.$element.hasClass('in')) return;

    var activesData;
    var actives = this.$parent && this.$parent.children().find('.collapse.in');

    if (actives && actives.length) {
        activesData = actives.data('bs.collapse');
        Plugin.call(actives, 'hide');
        activesData || actives.data('bs.collapse', null);
    }

    this.$element
        .addClass('collapse in');

};

Collapse.prototype.getParent = function () {
    return $(this.options.parent)
        .find('[data-toggle="collapse"][data-parent="' + this.options.parent + '"]')
        .each($.proxy(function (i, element) {
            var $element = $(element);
            this.addAriaAndCollapsedClass(getTargetFromTrigger($element), $element);
        }, this))
        .end();
};

Collapse.prototype.hide = function () {
    if (!this.$element.hasClass('in')) return;

    this.$element
        .removeClass('collapse in')
        .addClass('collapse');

};

Collapse.prototype.toggle = function () {
    this[this.$element.hasClass('in') ? 'hide' : 'show']();
    this.addAriaAndCollapsedClass(this.$element, this.$trigger);
};

Collapse.prototype.addAriaAndCollapsedClass = function ($element, $trigger) {
    var isOpen = this.$element.hasClass('in');

    $element.attr('aria-expanded', isOpen);
    $trigger
        .toggleClass('collapsed', !isOpen)
        .attr('aria-expanded', isOpen);
};

function getTargetFromTrigger($trigger) {
    var href;
    var target = $trigger.attr('data-target') ||
        (href = $trigger.attr('href')) && href.replace(/.*(?=#[^\s]+$)/, ''); // strip for ie7

    return $(target);
}


// PREHEADER BANNER
// ==========================
$(document).ready(function () {
    $(".close_btn_promo.escaping").click(function () {
        $("#preheader").css("display", "none");
    });
});

$(document).ready(function () {
    $(".close_btn_promo.escaping").click(function () {
        $("#preheader-gold").css("display", "none");
    });
});

// NEW POPUP
// ==========================

function showNewPopup(a) {
    $.ajax({
        type: "get", url: a, dataType: "xml", success: function (a) {
            $(".animated_popup").popup({
                content: $("<div>").html($(a).find("body").text()), show: function (a, b) {
                    var c = this, d = c.getCenter();
                    a.css({ top: -a.children().outerHeight(), left: d.left, opacity: 1 }).animate({ top: d.top }, 500, "easeOutBack", function () {
                        c.o.afterOpen.call(c);
                    });
                }
            });
        }
    });
}




$(document).on('click', '.show-size-guide', function () {
    $("html, body").animate({ scrollTop: 0 }, "slow");
    $('.popup-overlay-container').fadeIn(150);
    $('.popup-size-container').fadeIn(200);
});

$(document).on('click', '.close-size-popup', function () {
    $('.popup-overlay-container').hide();
    $('.popup-size-container').hide();
});








// COUNTER HOMEPAGE
// ==========================
function getLocale() {
    var lang = $('html').prop('lang');
    switch (lang) {
        case 'en': return 'en-US';
        case 'fr': return 'fr-FR';
        case 'de': return 'de-DE';
        case 'es': return 'es-ES';
        case 'ro': return 'ro-RO';
        default: return 'it-IT';
    }
}

function refreshRegisteredNumber() {
    var $register = $(".registered-numbers");

    // if not exists, do nothing
    if ($register.length == 0)
        return;

    $.get('/restful/utils/usercount/a6abe2e1-1008-4867-83ff-2d9b6632b432/5', function (data) {
        var counter = parseInt(data);

        $register.text(counter);
        $register.each(function () {
            $(this).prop('Counter', 0).animate({
                Counter: $(this).text()
            }, {
                duration: 3000,
                easing: 'swing',
                step: function (now) {
                    $(this).text(Math.ceil(now).toLocaleString(getLocale()));
                }
            });
        });
    });
}

$(document).ready(function () {

    refreshRegisteredNumber();
    setInterval(refreshRegisteredNumber, 300000);
});




// EXTRA CONTACT BAR
// ==========================
var mywindow = $(window);
var mypos = mywindow.scrollTop();
var up = false;
var newscroll;


mywindow.scroll(function () {
    newscroll = mywindow.scrollTop();
    if (newscroll > mypos && !up) {
        $('.extraban').addClass('hide-extra');
        up = !up;
    } else if (newscroll < mypos && up) {
        $('.extraban').removeClass('hide-extra');
        up = !up;
    }
    mypos = newscroll;
});




// COLLAPSE PLUGIN DEFINITION
// ==========================

function Plugin(option) {
    return this.each(function () {
        var $this = $(this);
        var data = $this.data('bs.collapse');
        var options = $.extend({}, Collapse.DEFAULTS, $this.data(), typeof option == 'object' && option);

        if (!data && options.toggle && /show|hide/.test(option)) options.toggle = false;
        if (!data) $this.data('bs.collapse', (data = new Collapse(this, options)));
        if (typeof option == 'string') data[option]();
    });
}

$.fn.collapse = Plugin;
$.fn.collapse.Constructor = Collapse;

$(document).on('click', '.toggle-btn', function () {
    $(this).toggleClass('selected');
});

$(document).on('click.bs.collapse.data-api', '[data-toggle="collapse"]', function (e) {
    var $this = $(this);

    e.preventDefault();

    var $target = getTargetFromTrigger($this);
    var data = $target.data('bs.collapse');
    var option = data ? 'toggle' : $this.data();

    Plugin.call($target, option);
});

$(document).on('touchstart mouseevent', '.has-dropdown', function (e) {
    var $this = $(this);
    if ($this.hasClass('no-dropdown-xs') && $(window).width() < 768) {
        return true;
    }

    e.stopImmediatePropagation();
    if (!$this.hasClass('show-submenu')) {
        $('.show-submenu').removeClass('show-submenu');
        $this.addClass('show-submenu');
        e.preventDefault();
    }
});

$(document).on('touchstart mouseevent', ':not(.has-dropdown, .has-dropdown *)', function () {
    if ($('.show-submenu').length > 0) {
        $('.show-submenu').removeClass('show-submenu');
        return false;
    }
});

var prefixes = {
    37: 1,
    225: 1,
    184: 7,
    64: 20,
    241: 27,
    88: 30,
    160: 31,
    20: 32,
    74: 33,
    67: 34,
    247: 34,
    99: 36,
    108: 39,
    228: 39,
    183: 40,
    41: 41,
    13: 43,
    248: 44,
    77: 44,
    58: 45,
    190: 46,
    161: 47,
    174: 48,
    56: 49,
    169: 51,
    151: 52,
    50: 53,
    11: 54,
    29: 55,
    44: 56,
    47: 57,
    230: 58,
    152: 60,
    14: 61,
    52: 61,
    100: 62,
    172: 63,
    166: 64,
    176: 64,
    191: 65,
    210: 66,
    111: 81,
    119: 82,
    233: 84,
    46: 86,
    217: 90,
    103: 91,
    173: 92,
    3: 93,
    127: 94,
    141: 95,
    106: 98,
    134: 212,
    65: 212,
    61: 213,
    214: 216,
    133: 218,
    84: 220,
    198: 221,
    145: 222,
    139: 223,
    85: 224,
    42: 225,
    21: 226,
    156: 227,
    209: 228,
    25: 229,
    148: 230,
    128: 231,
    196: 232,
    81: 233,
    158: 234,
    207: 235,
    39: 236,
    45: 237,
    51: 238,
    201: 239,
    87: 240,
    76: 241,
    40: 242,
    243: 243,
    9: 244,
    92: 245,
    104: 246,
    188: 248,
    189: 249,
    185: 250,
    68: 251,
    199: 252,
    57: 253,
    112: 254,
    221: 255,
    223: 256,
    24: 257,
    153: 258,
    242: 260,
    137: 261,
    239: 262,
    182: 262,
    244: 263,
    154: 264,
    150: 265,
    129: 266,
    34: 267,
    205: 268,
    116: 269,
    192: 290,
    66: 291,
    15: 297,
    73: 298,
    83: 299,
    82: 350,
    178: 351,
    131: 352,
    101: 353,
    107: 354,
    6: 355,
    147: 356,
    53: 357,
    69: 358,
    22: 359,
    130: 370,
    132: 371,
    63: 372,
    136: 373,
    7: 374,
    35: 375,
    1: 376,
    135: 377,
    197: 378,
    222: 380,
    250: 381,
    249: 382,
    97: 385,
    193: 386,
    17: 387,
    246: 389,
    54: 420,
    195: 421,
    126: 423,
    2: 971,
    71: 500,
    89: 500,
    36: 501,
    90: 502,
    203: 503,
    96: 504,
    159: 505,
    48: 506,
    168: 507,
    175: 508,
    98: 509,
    86: 590,
    28: 591,
    93: 592,
    62: 593,
    80: 594,
    180: 595,
    144: 596,
    200: 597,
    226: 598,
    8: 599,
    216: 670,
    10: 672,
    157: 672,
    27: 673,
    163: 674,
    171: 675,
    215: 676,
    187: 677,
    234: 678,
    70: 679,
    179: 680,
    235: 681,
    43: 682,
    165: 683,
    12: 684,
    236: 685,
    115: 686,
    155: 687,
    219: 688,
    170: 689,
    212: 690,
    138: 692,
    211: 737,
    118: 850,
    94: 852,
    142: 853,
    114: 855,
    123: 856,
    19: 880,
    220: 886,
    38: 891,
    149: 960,
    124: 961,
    110: 962,
    204: 963,
    105: 964,
    120: 965,
    186: 966,
    238: 967,
    167: 968,
    251: 970,
    102: 972,
    23: 973,
    181: 974,
    31: 975,
    140: 976,
    162: 977,
    213: 993,
    16: 994,
    79: 995,
    122: 996,
    113: 996,
    227: 998,
    30: 1242,
    18: 1246,
    5: 1264,
    4: 1268,
    231: 1284,
    232: 1340,
    121: 1345,
    26: 1441,
    78: 1473,
    206: 1649,
    146: 1664,
    143: 1670,
    91: 1671,
    125: 1758,
    59: 1767,
    229: 1784,
    177: 1787,
    60: 1809,
    218: 1868,
    117: 1869,
    109: 1876
};

var prefix_list = [
    1, 7, 20, 27, 30, 31, 32, 33, 34, 36, 39, 40, 41, 43, 44, 45, 46, 47, 48, 49, 51, 52, 53, 54, 55, 56, 57, 58,
    60, 61, 62, 63, 64, 65, 66, 81, 82, 84, 86, 90, 91, 92, 93, 94, 95, 98, 212, 213, 216, 218, 220, 221, 222,
    223, 224, 225, 226, 227, 228, 229, 230, 231, 232, 233, 234, 235, 236, 237, 238, 239, 240, 241, 242, 243,
    244, 245, 246, 248, 249, 250, 251, 252, 253, 254, 255, 256, 257, 258, 260, 261, 262, 263, 264, 265, 266,
    267, 268, 269, 290, 291, 297, 298, 299, 350, 351, 352, 353, 354, 355, 356, 357, 358, 359, 370, 371, 372,
    373, 374, 375, 376, 377, 378, 380, 381, 382, 385, 386, 387, 389, 420, 421, 423, 500, 501, 502, 503,
    504, 505, 506, 507, 508, 509, 590, 591, 592, 593, 594, 595, 596, 597, 598, 599, 670, 672, 673, 674, 675,
    676, 677, 678, 679, 680, 681, 682, 683, 684, 685, 686, 687, 688, 689, 690, 692, 737, 850, 852, 853, 855,
    856, 880, 886, 891, 960, 961, 962, 963, 964, 965, 966, 967, 968, 970, 971, 972, 973, 974, 975, 976, 977, 993,
    994, 995, 996, 998, 1242, 1246, 1264, 1268, 1284, 1340, 1345, 1441, 1473, 1649, 1664, 1670, 1671, 1758,
    1767, 1784, 1787, 1809, 1868, 1869, 1876
];

function setTelPrefix($dest, $source) {
    var country_id = $source.val();
    var prefix = typeof prefixes[country_id] != 'undefined' ? prefixes[country_id] : '';
    $dest.children('option').removeAttr('selected');
    $dest.val(prefix);
    $dest.children('option[value="' + prefix + '"]').attr("selected", "selected");
}

function fillCountryPrefix(options) {
    return this.each(function () {
        var $this = $(this);

        for (var i = 0; i < prefix_list.length; i++) {
            $this.append("<option value='" + prefix_list[i] + "'>+" + prefix_list[i] + "</option>");
        }

        var $source = $($this.attr('data-source'));
        setTelPrefix($this, $source);

        $this.hasBeenManuallyEntered = false;
        /*
            if ($this.val().length === 0) {
              setTelPrefix($this, $source);
            } else {
              $this.hasBeenManuallyEntered = true;
            }
        */
        $($source).change(function () {
            if (!$this.hasBeenManuallyEntered) {
                setTelPrefix($this, $source);
            }
        });
        /*
            $this.on('input', function() {
              var val = $this.val().trim();
              if (val.length > 0 && (val.indexOf('+') === 0 || val.indexOf('0') === 0)) {
                $this.hasBeenManuallyEntered = true;
              } else {
                $this.hasBeenManuallyEntered = false;
              }
            });
        */
    });
}

$.fn.fillCountryPrefix = fillCountryPrefix;

function loadQtyInputs() {
    $('.i-number').each(function () {
        const $this = $(this);

        if ($this.val() > $this.data('max')) {
            $this.css('color', 'red');
        }

        if (!$this.data('initialized')) {
            $('<a class="btn i-number-minus" href="#" role="button" tabindex="-1"><span class="fa fa-minus"></span></a>')
                .insertBefore($this)
                .on('click', function (event) {
                    event.preventDefault();
                    const value = getCurrentValue($this);
                    checkValue(value - 1);
                });

            $('<a class="btn i-number-minus" href="#" role="button" tabindex="-1"><span class="fa fa-plus"></span></a>')
                .insertAfter($this)
                .on('click', function (event) {
                    event.preventDefault();
                    const value = getCurrentValue($this);
                    checkValue(value + 1);
                });

            $this.on('keydown input', function (event) {
                const value = getCurrentValue($this);
                const key = (event.key || '').toUpperCase();

                if (key == 'ENTER') {
                    event.preventDefault();
                    $this.closest('form').trigger('submit');
                }
                else if (key == 'ARROWUP') {
                    checkValue(value + 1);
                }
                else if (key == 'ARROWDOWN') {
                    checkValue(value - 1);
                }
                else {
                    checkValue(value);
                }
            });

            $this.data('initialized', true);

            function getCurrentValue($this) {
                return $this.val().toString().replace(/[^0-9]/g, '') >> 0;
            }

            function checkValue(value) {
                const min = $this.data('min') >> 0;
                const max = $this.data('max') == null ? Number.MAX_SAFE_INTEGER : $this.data('max');

                if (value > max) {
                    $this.val(max);
                }
                else if (value < min) {
                    $this.val(min);
                }
                else {
                    $this.val(value);
                }
                $this.trigger('change');
            }

            $this.on('change', function () {
                const current = $this.val();
                const max = $this.data('max') == null ? Number.MAX_SAFE_INTEGER : $this.data('max');

                if (current <= max) {
                    $this.css('color', '');
                }
                else {
                    $this.css('color', 'red');
                }

                toggleSubmitButton();
            });

            function toggleSubmitButton() {
                const $form = $this.closest('form');
                const totalAmount = $form.find('.i-number').toArray()
                    .map(input => $(input).val() >> 0)
                    .reduce((a, b) => a + b, 0);
                const submitButton = $form.find('[type="submit"]').not('.btn-update');

                if (totalAmount > 0) {
                    submitButton.prop('disabled', false);
                }
                else {
                    submitButton.prop('disabled', true);
                }
            }
        }
    });
}


/*
  Legge un parametro dalla query string
*/
function getQueryStringValue(search_for) {
    var query = window.location.search.substring(1);
    var parms = query.split('&');
    for (var i = 0; i < parms.length; i++) {
        var pos = parms[i].indexOf('=');
        if (pos > 0 && search_for == parms[i].substring(0, pos)) {
            return parms[i].substring(pos + 1);;
        }
    }
    return "";
}

$(document).ready(function () {
    loadQtyInputs();

    $(window).on('scroll', function (event) {
        const headerUserHeight = $('#header-user').outerHeight()
        const headerNavigationHeight = $('#header-navigation').outerHeight()

        if($(window).width() > 767){
            if (window.scrollY > headerUserHeight) {
                $("#header-navigation, #header-user").addClass('stuck');
                $(".alert-box").addClass('alert-fixed');
                
            } else if(window.scrollY < Math.abs(headerUserHeight - headerNavigationHeight)*0.1) {
                $("#header-navigation, #header-user").removeClass('stuck');
                $(".alert-box").removeClass('alert-fixed');
            }
        }
    });


    $('.fill-country-prefix').fillCountryPrefix();

    $('#copy-billing').change(function () {
        if (this.checked) {
            $('#dispatch-form')
                .find('input[type="text"]')
                .each(function () {
                    this.value = $('#billing-form').find('#' + this.id).val();
                });
        }
        $('#dispatch-form')
            .find('input[type="text"]')
            .each(function () { this.disabled = this.checked; });
    });
});

$(function () {
    $.validator.messages.required = messages_requiredfield;
    $.validator.messages.digits = messages_digitsonly;
    $.validator.messages.minlength = jQuery.validator.format(messages_minlength);

    $('.tags-form input[type=checkbox]').on('change', function() {
        const $this = $(this);
        if ($this.is(':checked')) {
            $this.closest('.tags-form').find('input[type=checkbox]').not($this).prop('checked', false);
        }
    });
});