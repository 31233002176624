$(document).ready(function() {

    // get URL hash value and number of options (products)
    var urlHashValue = parseInt(window.location.hash.substr(1));
    var optionsNumbers = $.map($("#subscription-select option") ,function(option) {
        return parseInt(option.value);
    });
    
        // check if url hash value is valid
        if(urlHashValue != NaN && optionsNumbers.indexOf(urlHashValue) >= 0) {
            subscriptionChange(urlHashValue);
            $("#subscription-select").val(urlHashValue);
        }
        else {
            subscriptionChange($('.subscription-description').first().data("value"));
        }
    

    if ($('#subscription-select').length > 0) {
        appendToLang();
    }

    function appendToLang() {
        var id = $( "#subscription-select option:selected" ).attr('value')
        $('.lang-dropdown > li > a').each(function (index, value) {
        var str1 = $(this).attr('href').split('#')[0]
        var str2 = '#' + id
        $(this).attr('href', str1.concat(str2))
        });
        $('.curr-dropdown > li > a').each(function (index, value) {
        var str1 = $(this).attr('href').split('#')[0]
        var str2 = '#' + id
        $(this).attr('href', str1.concat(str2))
        });
    }

    // product select on change
    $("#subscription-select").on('change', function() {
        var selectValue = $(this).val();
        subscriptionChange(selectValue);
        appendToLang();
    });


    // hide cards and prices of not chosen options and change input value
    function subscriptionChange(n) {
        $('.subscription-price').each(function() {
            if($(this).data("value") == n) {
                $(this).fadeIn();
            }
            else {
                $(this).hide();
            }
        });
        $('.subscription-description').each(function() {
            if($(this).data("value") == n) {
                $(this).fadeIn();
            }
            else {
                $(this).fadeOut();
            }
        });
        $('#subscriptionOptionId').val(n);
    }


    // prevent click on btn-table from opening order-controls
    $(".btn-table").click(function(e) {
        e.stopPropagation();
    });


    function initializePayment(orderId) {
        $.ajax({
            type: "POST",
            url: '/restful/shopping/checkout/payment/initialize',
            data: { orderId: orderId },
            dataType: 'json',
            success: function(data) {
                if (data.success) {
                    window.location = '/current/checkout/billing';
                } else {
                    alert('Cannot pay this order.');
                }
            },
            failed: function(data) {
                showMessageBox('danger', msg['MSG_GENERIC_ERROR']);
            }
        });
    }


    // activate form ajax
    $('#subscription-activate-form').submit(function(event) {
        event.preventDefault();
        var $form = $(this);

        if(!$('#terms').is(':checked')) {
            showMessageBox('danger', msg['ERR_PRIVACY']);
        } else {
            $.ajax({
                type: $form.attr('method'),
                url: $form.attr('action'),
                data: $form.serialize(),
                dataType: 'json',
                beforeSend: function(data) {
                    $form.find(':input').prop('disabled', true);
                },
                success: function(data) {
                    if (data.success) {
                        initializePayment(data.data);
                    } else {
                        showMessageBox('danger', msg['MSG_GENERIC_ERROR'] + ' ' + msg['MSG_SUBSCRIPTION_FAILURE']);
                    }
                },
                failed: function(data) {
                    showMessageBox('danger', msg['MSG_GENERIC_ERROR']);
                },
                complete: function(data) {
                    $form.find(':input').prop('disabled', false);
                }
            });
        }
    });


    // profile reactivate form ajax
    $('.subscription-reactivate-form').submit(function(event) {
        event.preventDefault();
        var $form = $(this);

        $.ajax({
            type: $form.attr('method'),
            url: $form.attr('action'),
            data: $form.serialize(),
            dataType: 'json',
            beforeSend: function(data) {

            },
            success: function(data) {
                if (data.success == false) {
                    showMessageBox('danger', data.errors.message);
                } else {
                    window.location.reload();
                }
            },
            failed: function(data) {
                showMessageBox('danger', msg['MSG_GENERIC_ERROR']);
            },
            complete: function(data) {
            }
        });
    });


    // profile unsubscribe form ajax
    $('.subscription-unsubscribe-form').submit(function(event) {
        event.preventDefault();
        var $form = $(this);

        $.ajax({
            type: $form.attr('method'),
            url: $form.attr('action'),
            data: $form.serialize(),
            dataType: 'json',
            beforeSend: function(data) {

            },
            success: function(data) {
                if (data.success == false) {
                    showMessageBox('danger', data.errors.message);
                }
            },
            failed: function(data) {
                showMessageBox('danger', msg['MSG_GENERIC_ERROR']);
            },
            complete: function(data) {
                window.location.reload();
            }
        });
    });

    $('.subscription-change-form').submit(function(event) {
        event.preventDefault();
        var $form = $(this);

        $.ajax({
            type: $form.attr('method'),
            url: $form.attr('action'),
            data: $form.serialize(),
            dataType: 'json',
            beforeSend: function(data) {

            },
            success: function(data) {
                if (data.success == false) {
                    showMessageBox('danger', data.errors.message);
                }
            },
            failed: function(data) {
                showMessageBox('danger', msg['MSG_GENERIC_ERROR']);
            },
            complete: function(data) {
                window.location.reload();
            }
        });
    });

    // profile pay form ajax
    $('.pay-subscription').on('click', function(event) {
        var $this = $(this);
        initializePayment($this.attr('data-order-id'));
    });

});